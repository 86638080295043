@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-light .inverted {
  @apply bg-themes-light-invertedBackground text-themes-light-invertedText;
}

.theme-dark .inverted {
  @apply bg-themes-dark-invertedBackground text-themes-dark-invertedText;
}

:root {
  --background-color: #F3F4F6;
  --inverted-background-color: #1E1E1E;
  --text-color: #1E1E1E;
  --inverted-text-color: #F3F4F6;
  --primary-color: #1E90FF;
  --secondary-color: #0462dd;
  --success-color: #04e762;
  --information-color: #008bf8;
  --error-color: #dc0073;
  --warning-color: #f5b700;
  --white-color: #FFFFFF;
}

.theme-dark {
  --background-color: #1E1E1E;
  --inverted-background-color: #F3F4F6;
  --text-color: #F3F4F6;
  --inverted-text-color: #1E1E1E;
  --primary-color: #1E90FF;
  --secondary-color: #0462dd;
  --success-color: #04e762;
  --information-color: #008bf8;
  --error-color: #dc0073;
  --warning-color: #f5b700;
  --white-color: #FFFFFF;
}

.quiz-wrapper button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  color: var(--primary-color);
}

.quiz-wrapper button:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-clip: content-box;
  border: 2px solid #ccc;
  border-radius: 3px; /* Slightly rounded corners for a modern look */
  background-color: white;
  vertical-align: middle;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: var(--primary-color); /* Change this to your desired color */
  border: 2px solid var(--primary-color); /* Optional: match border color to the background */
}

/* Add a checkmark */
input[type="checkbox"]:checked::after {
  content: '';
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 3px;
}

/* Optionally style the hover effect */
input[type="checkbox"]:hover {
  border-color: var(--primary-color);
}

/* Adjust margin-right for spacing */
input[type="checkbox"].mr-2 {
  margin-right: 0.5rem;
}

/* Hide the default radio button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-clip: content-box;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: white;
  vertical-align: middle;
}

/* Style the radio button when it is checked */
input[type="radio"]:checked {
  background-color: var(--primary-color); /* Change this to your desired color */
  border: 2px solid var(--text-color); /* Optional: match border color to the background */
}

/* Optionally style the hover effect */
input[type="radio"]:hover {
  border-color: var(--primary-color);
}

/* Adjust margin-right for spacing */
input[type="radio"].mr-2 {
  margin-right: 0.5rem;
}


.text-white {
  color: var(--white-color);
}

.text-body {
  color: var(--text-color);
}

.text-inverted {
  color: var(--inverted-text-color);
}

.text-primary {
  color: var(--primary-color);
}

.text-success {
  color: var(--success-color);
}

.text-information {
  color: var(--information-color);
}

.text-error {
  color: var(--error-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.bg-body {
  background-color: var(--background-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-inverted {
  background-color: var(--inverted-background-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-success {
  background-color: var(--success-color);
}

.bg-information {
  background-color: var(--information-color);
}

.bg-error {
  background-color: var(--error-color);
}

.bg-warning {
  background-color: var(--warning-color);
}

.text-black {
  color: #121212;
}

.text-poetsen {
  font-family: "Poetsen One", cursive;
  font-weight: 400;
  font-style: normal;
}

.text-inter {
  font-family: "Inter", sans-serif;
  letter-spacing: -0.04em;
}

.text-roboto {
  font-family: "Roboto Slab", sans-serif;
}

.text-lato {
  font-family: "Lato", sans-serif;
}

.text-monterrat {
  font-family: "Montserrat", sans-serif;
}

.text-raleway {
  font-family: "Raleway", sans-serif;
}

.text-archivo-black {
  font-family: "Archivo Black", sans-serif;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: 'Tilt Neon','Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom scrollbar for Webkit browsers */
.slim-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.slim-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
}

.slim-scrollbar::-webkit-scrollbar-thumb {
  background: var(--inverted-text-color); /* Thumb color */
}

.slim-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Custom scrollbar for Firefox */
.slim-scrollbar {
  scrollbar-width: thin; /* 'thin' or can use 'auto' or a specific width like '8px' */
  scrollbar-color: var(--inverted-text-color) #f1f1f1; /* Thumb and track color */
}

.markdown-content a {
  text-decoration: underline;
}

.markdown-content hr {
  margin: 1.2em 0;
}

.bg-black-main {
  background-color: #1E1E1E;
}

.border-primary {
  border-color: var(--primary-color);
};

.border-secondary {
  border-color: var(--secondary-color);
};

.border-green-blue {
  border-color: #0fa;
};

@keyframes talk {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.talking {
  animation: talk 0.4s ease-in-out infinite;
}

.merch-display {
  transition: opacity 300ms ease-in-out;
}

.merch-display.hidden {
  opacity: 0;
}

.merch-display.visible {
  opacity: 1;
}

.spinner {
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0%, 25%, 100% {
      transform: translateX(0);
  }
  10%, 20% {
      transform: translateX(-10px);
  }
  15% {
      transform: translateX(10px);
  }
}

.markdown-content {
  word-wrap: break-word;
  width: 100%; /* Ensure content takes full width of container */
}

.markdown-content p {
  margin-bottom: 1rem;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.04em;
  font-weight: bold;
}

.markdown-content h1 {
  font-size: 2.5rem;
}

.markdown-content h2 {
  font-size: 2rem;
}

.markdown-content h3 {
  font-size: 1.75rem;
}

.markdown-content h4 {
  font-size: 1.5rem;
}

.markdown-content h5 {
  font-size: 1.25rem;
}

.markdown-content h6 {
  font-size: 1rem;
}

.markdown-content strong {
  font-weight: 700;
}

.markdown-content em {
  font-style: italic;
}

/* Lists */
.markdown-content ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.markdown-content ol {
  list-style-type: decimal;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.markdown-content li {
  margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown-content blockquote {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border-left: 4px solid #ccc;
  background-color: var(--text-color);
  color: #555;
}

/* Code blocks */
.markdown-content pre {
  background: var(--text-color);
  color: var(--inverted-text-color);
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
  word-wrap: break-word;
  margin-bottom: 1rem;
  white-space: pre-wrap; /* Allow line breaks in long code lines */
}

.markdown-content code {
  background: var(--text-color);
  color: var(--inverted-text-color);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  overflow-x: auto;
  white-space: pre-wrap; /* Allow line breaks in inline code */
}

/* Horizontal Rules */
.markdown-content hr {
  border: 0;
  border-top: 1px solid var(--primary-color);
  margin: 2rem 0;
}

/* Links */
.markdown-content a {
  color: var(--text-color);
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

pre {
  overflow-x: auto;
}

button {
  font-family: "Inter", sans-serif;
  letter-spacing: -0.04em;
  font-weight: bold;
}

.markdown-content img {
  width: 400px;
  max-width: 65vw;
  height: auto;
  border-radius: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.landing-page img {
  max-width: 275px;
  height: auto;
}

.shake {
  animation: shake 5s ease-in-out; /* Shake duration */
  animation-iteration-count: infinite;
  animation-delay: 5s; /* Time before the animation starts */
}

@keyframes pulse {
  0% {
      transform: scale(1);
      opacity: 0.9;
  }
  50% {
      transform: scale(1.1);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0.9;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.shimmer {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  background: linear-gradient(90deg, #1b03a1, #51a7fd, #1b03a1);
  letter-spacing: 5px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  background-size: 80%;
  animation: shine 3s linear infinite;
  position: relative;
}

@keyframes shine {
  0% {
    background-position-x: -300%;
  }
  100% {
    background-position-x: 300%;
  }
}

.text-green-blue {
  color: #0fa;
}

.glow-text {
  /*color: #ffffff;  Text color */
  text-shadow: 
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
  animation: pulsate 1.5s infinite alternate;
}

@keyframes pulsate {
  100% {
    text-shadow: 
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #0fa,
      0 0 80px #0fa,
      0 0 90px #0fa,
      0 0 100px #0fa,
      0 0 150px #0fa;
  }
  0% {
    text-shadow: 
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px #fff,
      0 0 10px #0fa,
      0 0 45px #0fa,
      0 0 55px #0fa,
      0 0 70px #0fa,
      0 0 80px #0fa;
  }
}

.subtle-glow-text {
  color: #ffffff; /* Text color */
  text-shadow: 
    0 0 2px #fff,
    0 0 4px #fff,
    0 0 6px #0fa,
    0 0 8px #0fa,
    0 0 10px #0fa;
  animation: subtle-pulsate 2s infinite alternate;
}

@keyframes subtle-pulsate {
  100% {
    text-shadow: 
      0 0 3px #fff,
      0 0 5px #fff,
      0 0 7px #0fa,
      0 0 9px #0fa,
      0 0 11px #0fa;
  }
  0% {
    text-shadow: 
      0 0 1px #fff,
      0 0 3px #fff,
      0 0 5px #0fa,
      0 0 7px #0fa,
      0 0 9px #0fa;
  }
}

.gradient-red-orange {
  background: linear-gradient(to right, red, orange);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: background-position 0.5s ease;
}

.gradient-red-orange:hover {
  background-position: right bottom;
}

.gradient-red-blue {
  background: linear-gradient(to right, red, #1E90FF);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: background-position 0.5s ease;
}

.gradient-red-blue:hover {
  background-position: right bottom;
}

.text-gradient-red-blue {
  background: linear-gradient(to right, red, #1E90FF);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.gradient-green-blue {
  background: linear-gradient(to right, green, #1E90FF);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: background-position 0.5s ease;
}

.gradient-green-blue:hover {
  background-position: right bottom;
}

.gradient-border-green-blue {
  border: 4px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to right, green, #1E90FF);
}

.text-gradient-green-blue {
  background: linear-gradient(to right, green, #1E90FF);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}


@keyframes slideDownBounce {
  0% { top: -100%; }
  60% { top: 10%; }
  80% { top: -5%; }
  100% { top: 0; }
}

.top-0 {
  animation: slideDownBounce 0.5s ease-out forwards;
}

.-top-full {
  top: -100%;
  transition: top 0.5s ease-in;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

.gradient-border-green-blue {
  border: 4px solid transparent;
  border-radius: 100%; /* Optional: for rounded corners */
  background-image: linear-gradient(white, white), linear-gradient(to right, #4ADE80, #52a6f5, #285aeb);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0px; /* Optional: adjust to your needs */
}

.gradient-border-green-blue-active {
  border: 4px solid transparent;
  border-radius: 100%; /* Optional: for rounded corners */
  background-image: linear-gradient(to right, #4ADE80, #52a6f5, #285aeb), linear-gradient(to right, #4ADE80, #52a6f5, #285aeb);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0px; /* Optional: adjust to your needs */
}

.gradient-border-insta {
  border: 3px solid transparent;
  border-radius: 100%; /* Optional: for rounded corners */
  background-image: linear-gradient(white, white), linear-gradient(to right, #d6249f, #285aeb, #f5a905, #d15d04, #d6249f);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0px; /* Optional: adjust to your needs */
}
